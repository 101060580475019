import React, { useState, useEffect, useRef } from 'react';
import {
  Flex,
  Box
} from '@chakra-ui/react';

import './App.css';
import { auth } from './Firebase';

import Const from './Constants';

import NavBar from './NavBar';
import SideBar from './SideBar';
import Notes from './Notes';
import Tasks from './Tasks';
import Interviews from './Interviews';
import SignInView from './SignInView';
import TaskDetailsBar from './TaskDetailsBar';

function App() {
  const [appMode, setAppMode] = useState(Const.sTasks); // Load in tasks mode
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  
  const [currentTask, setCurrentTask] = useState(null);

  const [tasks, setTasks] = useState({
    "Todo": [],
    "In Progress": [],
    "Done": []
  });

  // Do not show compalted tasks by default
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const isFirstRender = useRef(true); // Track if it's the first render

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first call
      isFirstRender.current = false;
      return;
    }

    loadTasksData();
  // eslint-disable-next-line
  }, [showCompletedTasks]);

  async function loadTasksData() {
    try {
      // Load all tasks from backend
      const queryParams = new URLSearchParams({
        includeDone: showCompletedTasks
      });
      const idToken = await auth.currentUser.getIdToken(true);
      const theURL = `${process.env.REACT_APP_BASE_URL}/tasks?${queryParams}`;

      const response = await fetch(theURL, {
        headers: new Headers({
          Authorization: idToken
        })
      });
      const tasks = await response.json();
      setTasks(tasks);
    } catch (error) {
      console.error(error.message);
    }
  }

  async function handleUpdateTask(taskId, title, description, done, status) {
    try {
      if(title === null && description === null && done === null && status === null) {
        throw new Error('handleUpdateTask: All params are null');
      }

      const idToken = await auth.currentUser.getIdToken(true);
      const theURL = process.env.REACT_APP_BASE_URL + '/tasks';

      let body = {
        taskId
      };

      if(title !== null) {
        body[Const.sTitle] = title;
      }

      if(description !== null) {
        body[Const.sDescription] = description;
      }

      if(done !== null) {
        body[Const.sDone] = done;
      }

      if(status !== null) {
        body[Const.sStatus] = status;
      }

      await fetch(theURL, {
        method: 'PATCH',
        headers: new Headers({
          Authorization: idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(body)
      });
  
      // Now update the task data
      await loadTasksData();
  
    } catch (error) {
      console.error(error.message);
    }
  };

  function appModeToComponentMap(appMode) {
    if(appMode === Const.sNotes) {
      return <Notes auth={auth}/>;
    }
    else if (appMode === Const.sTasks) {
      return <Tasks
        auth={auth}
        isSignedIn={isSignedIn}
        setIsSignedIn={setIsSignedIn}
        setAppMode={setAppMode}
        currentTask={currentTask}
        setCurrentTask={setCurrentTask}
        tasks={tasks}
        setTasks={setTasks}
        loadTasksData={loadTasksData}
        handleUpdateTask={handleUpdateTask}
      />
    }
    else if(appMode === Const.sInterviews) {
      return <Interviews auth={auth}/>;
    }
    else {
      return null;
    }
  }

  return (
    <Box className="App">
      {
      appMode === Const.sSignIn ?
      <SignInView
          auth={auth}
          setAppMode={setAppMode}
          isSignedIn={isSignedIn}
          setIsSignedIn={setIsSignedIn} />
      :
      <>
      <NavBar
        auth={auth}
        setIsSignedIn={setIsSignedIn}
        appMode={appMode}
        setAppMode={setAppMode}
        onOpen={toggleSidebar}
        showCompletedTasks={showCompletedTasks}
        setShowCompletedTasks={setShowCompletedTasks}
        loadTasksData={loadTasksData} />
      <Flex>
            {isSidebarOpen && <SideBar appMode={appMode} setAppMode={setAppMode} />}
            { /* Mail area */
              appModeToComponentMap(appMode)
            }
            { /* Task details bar  */
            currentTask !== null && appMode === Const.sTasks &&
              <TaskDetailsBar
                auth={auth}
                currentTask={currentTask}
                setCurrentTask={setCurrentTask}
                loadTasksData={loadTasksData}
                handleUpdateTask={handleUpdateTask}
              />
            }
      </Flex>
      </>
      }
    </Box>
  );
}

export default App;
