import React from "react";
import {
    Flex,
    Spacer,
    Text,
    Button
} from "@chakra-ui/react";

import { CheckIcon } from '@chakra-ui/icons';

import Const from './Constants';

const NavBar = ({ auth, setIsSignedIn, appMode, setAppMode, onOpen, showCompletedTasks, setShowCompletedTasks, loadTasksData }) => {

    async function handleClickSignOut() {
        await auth.signOut();
        await setIsSignedIn(false);
        await setAppMode(Const.sSignIn);
      }
    
    return (
        <Flex
            justifyContent='right'
            alignItems='center'
            padding='10px'
            background={Const.Gray46}
            width='100vw'
            borderBottom="1px solid"
            borderColor={Const.Gray64}
        >
            <Spacer/>
            <Flex align="center" justify="center">
                {
                    // <Image src='logo192.png' height={'32px'} marginRight={'4px'} />
                }
                <Text fontWeight={'bold'} fontSize={18} color={Const.ActiveColor}>{Const.sAppName}</Text>
            </Flex>
            <Spacer/>
            { appMode === Const.sTasks &&
            <Button
                leftIcon={<CheckIcon/>}
                size={'sm'}
                height={'30px'}
                background={ showCompletedTasks ? Const.DoneColor : Const.Gray36}
                color={Const.Gray165}
                _hover={{ background: showCompletedTasks ? Const.DoneLightColor : Const.Gray69 }}
                fontSize={12}
                onClick={() => {
                    setShowCompletedTasks(!showCompletedTasks);
                }}>
                Show complated
            </Button>
            }
            <Button
                size={'sm'}
                height={'30px'}
                fontSize={12}
                background={Const.Gray36}
                color={Const.Gray165}
                _hover={{ background: Const.Red }}
                onClick={handleClickSignOut}
                marginLeft={2}
            >
                Sign out
            </Button>
        </Flex>
    );
};

export default NavBar;
