import {
    Button,
    VStack
} from '@chakra-ui/react';
// import { useState, useEffect } from 'react';

import { AddIcon } from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';

const NotesBar = ({ auth, notesData, currentNote, setCurrentNote, loadNotesData }) => {
  const handleAddNote = async () => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const theURL = process.env.REACT_APP_BASE_URL + '/notes';
      const response = await fetch(theURL, {
        method: 'POST',
        headers: new Headers({
          Authorization: idToken,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      });

      // Check if the request was successful
      if(!response.ok) {
        throw new Error('Failed to add note');
      }

      // Parse the added note from the response
      const addedNote = await response.json();

      // Now update the task data
      loadNotesData();

      // Set the newly added note as the current note
      setCurrentNote(addedNote);

    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <VStack
        w={'240px'}
        minW={'240px'}
        maxW={'240px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        borderLeft="1px solid"
        borderColor={Const.Gray64}
        spacing={'4px'}
    >
        { !notesData.empty && notesData.map((note, index) => (
          <SideBarButton
              key={index}
              current={currentNote && note.id === currentNote.id}
              title={!note.title ? 'New Note' : note.title}
              action={() => setCurrentNote(note)}
          />
      ))}
      <Button
            leftIcon={<AddIcon/>}
            size={'sm'}
            minH={'26px'}
            width={'100%'}
            background={Const.Gray46}
            color={Const.Gray165}
            _hover={{ background: Const.Gray69 }}
            onClick={() => handleAddNote()}
        >
          Add note
      </Button>
    </VStack>
  );
};

export default NotesBar;
