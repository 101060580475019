// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCNvO_JfJFvGgAZodrFogsrl3WrYChAqPM",
    authDomain: "mihrapp.firebaseapp.com",
    projectId: "mihrapp",
    storageBucket: "mihrapp.appspot.com",
    messagingSenderId: "921581185112",
    appId: "1:921581185112:web:e566a5e85f4620e459422d"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
