import React, { useEffect } from 'react';
import {
  Flex,
  VStack,
  Text,
  Spacer
} from '@chakra-ui/react';

import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import Const from './Constants';
import { handleAuthStateChangeCallback } from './Utils';

// Configure FirebaseUI.
const uiConfig = {
  // Popup sign in flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

function SignInView({
  auth,
  setAppMode,
  isSignedIn,
  setIsSignedIn
}) {
  async function onSignIn(signedIn) {
    if(signedIn) {
      // Sign in is successful
      setAppMode(Const.sTasks);
    }
  }

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    handleAuthStateChangeCallback(auth, isSignedIn, setIsSignedIn, onSignIn);
  // eslint-disable-next-line
  }, []);

  return (    
      <Flex marginTop={24}
        justify="center"
      >
        <VStack spacing={2}
          padding={6}
          borderRadius="md"
          border="1px solid"
          borderColor={Const.Gray64}
        >
          <Text fontWeight={'bold'} fontSize={18} color={Const.ActiveColor}>{Const.sAppName}</Text> {/* Solar Flare */}
          <Text fontSize={12} color={Const.Gray165}>Choose your sign in option</Text> {/* Solar Flare */}
          <Spacer/>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </VStack>
      </Flex>
  );
}

export default SignInView;
