import {
    Box,
    VStack,
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon
} from '@chakra-ui/react';

// import { useState, useEffect } from 'react';
// import { AddIcon } from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';

const InterviewsBar = ({ auth, interviewsData, setInterviewsData, currentInterview, setCurrentInterview }) => {
  return (
    <VStack
        w={'260px'}
        minW={'260px'}
        maxW={'260px'}
        height={'100vh'}
        background={Const.Gray46}
        padding={'10px'}
        borderLeft="1px solid"
        borderColor={Const.Gray64}
        spacing={'4px'}>
        <Accordion defaultIndex={[0, 1, 2]} allowMultiple width="100%">
          {/* Global Templates Section */}
          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={14} fontWeight="bold" color={Const.Gray180}>
                  {`${Const.sAppName} Templates`}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {interviewsData.gloablTemplate.map((template) => (
                <SideBarButton
                    key={template.id}
                    current={currentInterview && currentInterview.data.id === template.id}
                    title={template.title}
                    action={()=> {
                      setCurrentInterview({
                        type: Const.sGlobal,
                        data: template
                      });
                    }}
                />
              ))}
            </AccordionPanel>
          </AccordionItem>

          {/* Local Templates Section */}
          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={14} fontWeight="bold" color={Const.Gray180}>
                  {'Own Templates'}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {interviewsData.localTemplate.map((template) => (
                <SideBarButton
                    key={template.id}
                    current={currentInterview && currentInterview.data.id === template.id}
                    title={template.title}
                    action={()=> {
                      setCurrentInterview({
                        type: Const.sLocal,
                        data: template
                      });
                    }}
                />
              ))}
            </AccordionPanel>
          </AccordionItem>

          {/* Interviews Section */}
          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontSize={14} fontWeight="bold" color={Const.Gray180}>
                  Interview Notes
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {interviewsData.interviews.map((interview) => (
                <SideBarButton
                    key={interview.id}
                    current={currentInterview && currentInterview.data.id === interview.id}
                    title={interview.title}
                    action={()=> {
                      setCurrentInterview({
                        type: Const.sInterview,
                        data: interview
                      });
                    }}
                />
              ))}
            </AccordionPanel>
          </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default InterviewsBar;
