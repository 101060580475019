import React, { useState, useEffect } from 'react';
import {
    Flex,
    Box
} from "@chakra-ui/react";

// import { DeleteIcon } from '@chakra-ui/icons';

// import Const from './Constants';
import InterviewsBar from './InterviewsBar.js';
import InterviewDetails from './InterviewDetails.js';

const Interviews = ({ auth }) => {
    const [interviewsData, setInterviewsData] = useState({
        gloablTemplate: [],
        localTemplate: [],
        interviews: []
    });

    const [currentInterview, setCurrentInterview] = useState(null);

    useEffect(() => {
        loadInterviewsData();
    // eslint-disable-next-line
    }, []);

    async function loadInterviewsData() {
        try {
          const idToken = await auth.currentUser.getIdToken(true);
          const theURL = `${process.env.REACT_APP_BASE_URL}/interviews`;
    
          const response = await fetch(theURL, {
            headers: new Headers({
              Authorization: idToken
            })
          });
          const interviewsData = await response.json();
          setInterviewsData(interviewsData);
        } catch (error) {
          console.error(error.message);
        }
      }

    return (
        <Box
            display="flex"
            justifyContent='center'
            alignItems='center'
            height="100vh"
            width={'100vw'}
        >
            <Flex w={'100%'}>
                <InterviewsBar
                  auth={auth}
                  interviewsData={interviewsData}
                  setInterviewsData={setInterviewsData}
                  currentInterview={currentInterview}
                  setCurrentInterview={setCurrentInterview}
                />
                <InterviewDetails
                    auth={auth}
                    interviewsData={interviewsData}
                    setInterviewsData={setInterviewsData}
                    currentInterview={currentInterview}
                    setCurrentInterview={setCurrentInterview}
                />
            </Flex>
        </Box>
    );
};

export default Interviews;
