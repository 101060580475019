import React, { useState, useEffect, useRef } from "react";

import {
    Button,
    HStack,
    Spacer,
    Input,
    VStack,
    Textarea,
    Text
} from '@chakra-ui/react';

import { DeleteIcon, AddIcon, StarIcon, CopyIcon } from '@chakra-ui/icons';

import Const from './Constants';

const InterviewDetails = ({ auth, interviewsData, setInterviewsData, currentInterview, setCurrentInterview }) => {
    // Used as a value for the title input
    const [title, setTitle] = useState(currentInterview ? currentInterview.data.title : '');
    const titleInputRef = useRef(null);

    const [body, setBody] = useState(currentInterview ? currentInterview.data.body : '');
    const bodyInputRef = useRef(null);

    const [aiSummary,setAiSummary] = useState(currentInterview ? currentInterview.data.aiSummary : '');
    const aiSummaryInputRef = useRef(null);

    useEffect(() => {
        if(currentInterview) {
          if(currentInterview.title !== title) {
            setTitle(currentInterview.data.title);
          }

          if(currentInterview.body !== body) {
            setBody(currentInterview.data.body);
          }
        }
    // eslint-disable-next-line
    }, [currentInterview]);

    async function handleAISummary() {
        try {
            // Load all tasks from backend
            const queryParams = new URLSearchParams({
                title: currentInterview.data.title,
                body: body
            });
            const idToken = await auth.currentUser.getIdToken(true);
            const theURL = `${process.env.REACT_APP_BASE_URL}/interviews/summary?${queryParams}`;
        
            const response = await fetch(theURL, {
                headers: new Headers({
                    Authorization: idToken
                }),
            });
            const responseJson = await response.json();
            setAiSummary(responseJson.summary);
        } catch (error) {
            console.error(error.message);
        }
    }

    function handleCreateInterview() {
        let copyInterviewsData = JSON.parse(JSON.stringify(interviewsData));

        // TODO send the add interview request to server here.

        const interview = {
            id: "fdafdfdaasfda323432dfa", // TODO: get from server
            parentTemplate: currentInterview.data.id, // Creating from the current selected template
            title: currentInterview.data.title + ': <Candidate\'s Name>',
            body: currentInterview.data.body
        };

        copyInterviewsData.interviews.push(interview);
        setInterviewsData(copyInterviewsData);
    }

    return (
        currentInterview && <VStack w={'100%'} align='left' margin={'10px'}>
            <HStack>
                <Input
                    ref={titleInputRef} // Set the reference to the input
                    isReadOnly={currentInterview.type !== Const.sInterview}
                    fontSize={22}
                    fontWeight={'bold'}
                    value={title}
                    border={"none"}
                    _hover={{ cursor: currentInterview.type !== Const.sInterview ? 'default' : 'text' }}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                    onBlur={(event) => {
                        // handleBlurTitle(event);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            // Remove focus
                            titleInputRef.current.blur();
                            event.preventDefault(); // Prevents any default action
                        }
                    }}
                />
                <Spacer />
                { /* Create interview button */
                currentInterview.type !== Const.sInterview && <Button
                    leftIcon={<AddIcon/>}
                    size={'sm'}
                    height={'30px'}
                    minWidth={'120px'}
                    background={Const.DoneColor}
                    color={Const.White}
                    _hover={{ background: Const.DoneLightColor }}
                    fontSize={12}
                    onClick={() => {
                        handleCreateInterview();
                    }}>
                    Create interview
                </Button>
                }
                { /* Interview AI summary button */
                currentInterview.type === Const.sInterview && <Button
                    leftIcon={<StarIcon/>}
                    size={'sm'}
                    height={'30px'}
                    minWidth={'120px'}
                    background={Const.DoneColor}
                    color={Const.White}
                    _hover={{ background: Const.DoneLightColor }}
                    fontSize={12}
                    onClick={() => {
                        handleAISummary();
                    }}>
                    AI Summary
                </Button>
                }
                { /* Delete button */
                currentInterview.type !== Const.sGlobal && <Button
                    leftIcon={<DeleteIcon/>}
                    iconSpacing={0}
                    size={'sm'}
                    height={'30px'}
                    background={Const.Gray69}
                    color={Const.Gray165}
                    _hover={{ background: Const.Red }}
                    fontSize={10}
                    onClick={() => {
                        // handleDeleteNote(currentNote.id);
                    }}
                />
                }
            </HStack> {/* end of header */}
            <HStack flex="1">
                <VStack w='100%' h={'100%'}>
                    <Textarea
                        ref={bodyInputRef} // Set the reference to the input
                        isReadOnly={currentInterview.type !== Const.sInterview}
                        fontSize={16}
                        flex="1" // Allow Textarea to grow and take up remaining space
                        height="100%"
                        value={body}
                        resize="none"
                        border={"none"}
                        placeholder="Enter the note here"
                        _placeholder={{ color: Const.Gray100 }}
                        _hover={{ cursor: currentInterview.type !== Const.sInterview ? 'default' : 'text' }}
                        onChange={(event) => {
                            setBody(event.target.value);
                        }}
                        onBlur={(event) => {
                            // handleBlurBody(event);
                        }}
                    />
                </VStack>

                { aiSummary &&
                <>
                {/* Green Delimiter Line */}
                
                <VStack
                    w='100%'
                    alignItems={'left'}
                    h={'100%'}
                    border={'1px'}
                    padding={2}
                    borderColor={Const.DoneColor}
                    borderRadius="md"
                >
                    <HStack>
                        <Text
                            align={'left'}
                            fontSize={16}
                            fontWeight={'bold'}
                            color={Const.DoneColor}
                        >
                            AI Summary
                        </Text>
                        <Spacer />
                        { /* Copy button */ }
                        <Button
                            leftIcon={<CopyIcon/>}
                            iconSpacing={0}
                            size={'sm'}
                            height={'30px'}
                            background={Const.DoneColor}
                            color={Const.White}
                            _hover={{ background: Const.DoneLightColor }}
                            fontSize={10}
                            onClick={() => {
                                navigator.clipboard.writeText(aiSummary);
                            }}
                        />
                    </HStack>
                    <Textarea
                        ref={aiSummaryInputRef} // Set the reference to the input
                        isReadOnly={currentInterview.type !== Const.sInterview}
                        fontSize={16}
                        flex="1" // Allow Textarea to grow and take up remaining space
                        value={aiSummary}
                        resize="none"
                        border={"none"}
                        placeholder="Enter the note here"
                        _placeholder={{ color: Const.Gray100 }}
                        _hover={{ cursor: currentInterview.type !== Const.sInterview ? 'default' : 'text' }}
                        onChange={(event) => {
                            setBody(event.target.value);
                        }}
                        onBlur={(event) => {
                            // handleBlurBody(event);
                        }}
                    />
                </VStack>
                </>
                }
            </HStack>
        </VStack>
    );
};

export default InterviewDetails;
