import {
    VStack
} from '@chakra-ui/react';

import {
    EditIcon,
    CheckCircleIcon,
    ChatIcon,
    ViewIcon
} from '@chakra-ui/icons';

import SideBarButton from './SideBarButton';

import Const from './Constants';

const SideBar = ({ appMode, setAppMode }) => {
  return (
    <VStack
        width={'240px'}
        minW={'240px'}
        maxW={'240px'}
        background={Const.Gray46}
        padding={'10px'}
        h={'100vh'}
        spacing={'8px'}
    >
        <SideBarButton
            current={appMode === Const.sTasks}
            icon={<CheckCircleIcon/>}
            title={Const.sTasks}
            action={() => setAppMode(Const.sTasks)}
        />
        <SideBarButton
            current={appMode === Const.sNotes}
            icon={<EditIcon/>}
            title={Const.sNotes}
            action={() => setAppMode(Const.sNotes)}
        />
        { false && 
        <SideBarButton
            current={appMode === Const.s1on1Meetings}
            icon={<ChatIcon/>}
            title={Const.s1on1Meetings}
            action={() => setAppMode(Const.s1on1Meetings)}
        />
        }
        <SideBarButton
            current={appMode === Const.sInterviews}
            icon={<ViewIcon/>}
            title={Const.sInterviews}
            action={() => setAppMode(Const.sInterviews)}
        />
    </VStack>
  );
};

export default SideBar;
