import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { ChakraProvider, CSSReset, extendTheme } from '@chakra-ui/react'; // Import ChakraProvider

import Const from './Constants';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        backgroundColor: Const.Gray31
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <CSSReset/>
    <App/>
  </ChakraProvider>
);
